import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import { selectBrands } from "../../brands/BrandSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { selectCategories } from "../../categories/CategoriesSlice";
import { ProductCard } from "../../products/components/ProductCard";
import {
  deleteProductByIdAsync,
  fetchProductsAsync,
  selectProductIsFilterOpen,
  selectProductTotalResults,
  selectProducts,
  toggleFilters,
  undeleteProductByIdAsync,
} from "../../products/ProductSlice";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ClearIcon from "@mui/icons-material/Clear";
import { ITEMS_PER_PAGE } from "../../../constants";

const sortOptions = [
  { name: "Price: low to high", sort: "price", order: "asc" },
  { name: "Price: high to low", sort: "price", order: "desc" },
];

export const AdminDashBoard = () => {
  const [filters, setFilters] = useState({});
  const brands = useSelector(selectBrands);
  const categories = useSelector(selectCategories);
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(1);
  const products = useSelector(selectProducts);
  const dispatch = useDispatch();
  const theme = useTheme();
  const is500 = useMediaQuery(theme.breakpoints.down(500));
  const isProductFilterOpen = useSelector(selectProductIsFilterOpen);
  const totalResults = useSelector(selectProductTotalResults);

  const is1200 = useMediaQuery(theme.breakpoints.down(1200));
  const is800 = useMediaQuery(theme.breakpoints.down(800));
  const is700 = useMediaQuery(theme.breakpoints.down(700));
  const is600 = useMediaQuery(theme.breakpoints.down(600));
  const is488 = useMediaQuery(theme.breakpoints.down(488));

  useEffect(() => {
    setPage(1);
  }, [totalResults]);

  useEffect(() => {
    const finalFilters = {
      ...filters,
      pagination: { page, limit: ITEMS_PER_PAGE },
      sort,
    };
    dispatch(fetchProductsAsync(finalFilters));
  }, [filters, sort, page]);
  const handleBrandFilters = (e) => {
    setFilters((prevFilters) => {
      const newBrandFilters = e.target.checked
        ? [...prevFilters.brand, e.target.value]
        : prevFilters.brand.filter((brand) => brand !== e.target.value);

      return { ...prevFilters, brand: newBrandFilters };
    });
  };

  const handleCategoryFilters = (e) => {
    setFilters((prevFilters) => {
      const newCategoryFilters = e.target.checked
        ? [...prevFilters.category, e.target.value]
        : prevFilters.category.filter(
            (category) => category !== e.target.value
          );

      return { ...prevFilters, category: newCategoryFilters };
    });
  };

  const handleProductDelete = (productId) => {
    dispatch(deleteProductByIdAsync(productId));
  };

  const handleProductUnDelete = (productId) => {
    dispatch(undeleteProductByIdAsync(productId));
  };

  const handleFilterClose = () => {
    dispatch(toggleFilters());
  };

  return (
    <>
      <motion.div
        style={{
          position: "fixed",
          backgroundColor: "white",
          height: "100vh",
          padding: "1rem",
          overflowY: "scroll",
          width: is500 ? "100vw" : "30rem",
          zIndex: 500,
        }}
        variants={{
          show: { transform: "translateX(0)" },
          hide: { transform: "translateX(-100%)" },
        }}
        initial="hide"
        animate={isProductFilterOpen === true ? "show" : "hide"}
        transition={{ ease: "easeInOut", duration: 0.7, type: "spring" }}
      >
        {/* Filters Section */}
        <Stack
          mb={"5rem"}
          sx={{ scrollBehavior: "smooth", overflowY: "scroll" }}
        >
          <Typography variant="h4">New Arrivals</Typography>
          <IconButton
            onClick={handleFilterClose}
            style={{ position: "absolute", top: 15, right: 15 }}
          >
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <ClearIcon fontSize="medium" />
            </motion.div>
          </IconButton>

          {/* Brand Filters */}
          <Stack mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="brand-filters"
                id="brand-filters"
              >
                <Typography>Brands</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <FormGroup onChange={handleBrandFilters}>
                  {brands?.map((brand) => (
                    <motion.div
                      key={brand._id}
                      whileHover={{ x: 5 }}
                      whileTap={{ scale: 0.9 }}
                      style={{ width: "fit-content" }}
                    >
                      <FormControlLabel
                        sx={{ ml: 1 }}
                        control={<Checkbox />}
                        label={brand.name}
                        value={brand._id}
                      />
                    </motion.div>
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Stack>

          {/* Category Filters */}
          <Stack mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="category-filters"
                id="category-filters"
              >
                <Typography>Category</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <FormGroup onChange={handleCategoryFilters}>
                  {categories?.map((category) => (
                    <motion.div
                      key={category._id}
                      style={{ width: "fit-content" }}
                      whileHover={{ x: 5 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <FormControlLabel
                        sx={{ ml: 1 }}
                        control={<Checkbox />}
                        label={category.name}
                        value={category._id}
                      />
                    </motion.div>
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </motion.div>

      <Stack rowGap={5} mt={is600 ? 2 : 5} mb={"3rem"}>
        {/* Sort Options */}
        <Stack
          flexDirection={"row"}
          mr={"2rem"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnGap={5}
        >
          <Stack alignSelf={"flex-end"} width={"12rem"}>
            <FormControl fullWidth>
              <InputLabel id="sort-dropdown">Sort</InputLabel>
              <Select
                variant="standard"
                labelId="sort-dropdown"
                label="Sort"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  // Handle reset case
                  if (selectedValue === null) {
                    setSort(null); // Reset sort
                  } else {
                    setSort(selectedValue); // Set selected option
                  }
                }}
                value={sort}
              >
                <MenuItem
                  value={null}
                  sx={{ backgroundColor: "text.secondary", color: "white" }}
                >
                  Reset
                </MenuItem>
                {sortOptions.map((option) => (
                  <MenuItem key={option.sort} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignContent="center"
        >
          {products.map((product) => (
            <Stack key={product._id}>
              <Stack sx={{ opacity: product.isDeleted ? 0.7 : 1 }}>
                <ProductCard
                  id={product._id}
                  title={product.title}
                  thumbnail={product.thumbnail}
                  brand={product.brand.name}
                  price={product.price}
                  isAdminCard={true}
                />
              </Stack>
              <Stack
                paddingLeft={2}
                paddingRight={2}
                flexDirection={"row"}
                justifySelf={"flex-end"}
                alignSelf={"flex-end"}
                columnGap={is488 ? 1 : 2}
              >
                <Button
                  component={Link}
                  to={`/admin/product-update/${product._id}`}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={() =>
                    product.isDeleted
                      ? handleProductUnDelete(product._id)
                      : handleProductDelete(product._id)
                  }
                  color="error"
                  variant="outlined"
                >
                  {product.isDeleted ? "Un-delete" : "Delete"}
                </Button>
              </Stack>
            </Stack>
          ))}
        </Grid>
        <Stack
          sx={{
            alignSelf: is488 ? "center" : "flex-end",
            marginRight: is488 ? 0 : 5,
          }}
        >
          <Pagination
            size={is488 ? "medium" : "large"}
            page={page}
            onChange={(e, page) => setPage(page)}
            count={Math.ceil(totalResults / ITEMS_PER_PAGE)}
            variant="outlined"
            shape="rounded"
          />
          <Typography sx={{ textAlign: "center" }}>
            Showing {(page - 1) * ITEMS_PER_PAGE + 1} to{" "}
            {Math.min(page * ITEMS_PER_PAGE, totalResults)} of {totalResults}{" "}
            results
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};
